<template>
  <div>
    <DescriptionCard :servicos="this.servicos" />
    <v-container>
      <v-row max-width="100%" class="justify-center grid-services">
        <div v-for="servico in servicos" :key="servico.id">
          <label class="option_item">
            <input
              class="checkbox"
              type="checkbox"
              :value="servico"
              v-model="selected"
            />
            <div class="option_inner">
              <div class="tickmark"></div>
              <div class="icon">
                <img :src="servico.imageURL" alt="" height="60" width="60" />
              </div>
              <div class="name">{{ servico.name.toUpperCase() }}</div>
            </div>
          </label>
        </div>
      </v-row>
    </v-container>

    <DocumentsDialog
      :services="this.selected"
      :documents="this.uniqueDocuments"
      @salvarSelect="saveServices"
      @listDocuments="listDocuments"
    />
  </div>
</template>

<script>
import api from "@/services/conexao-api.js";
import "@mdi/font/css/materialdesignicons.css";
import DescriptionCard from "./DescriptionCard.vue";
import DocumentsDialog from "./DocumentsDialog.vue";

export default {
  name: "AttendanceMain",
  components: {
    DocumentsDialog,
    DescriptionCard,
  },

  data() {
    return {
      servicos: [],
      drawer: null,
      selected: [],
      uniqueDocuments: [],
      icons: { iconfont: "mdi" },
    };
  },

  mounted() {
    api.get("/services/active-services").then((response) => {
      this.servicos = response.data;
    });

    if (sessionStorage.getItem("selected")) {
      try {
        this.selected = JSON.parse(sessionStorage.getItem("selected"));
      } catch (e) {
        sessionStorage.removeItem("selected");
      }
    }
  },

  methods: {
    saveServices() {
      const parsed = JSON.stringify(this.selected);
      sessionStorage.setItem("selected", parsed);
      this.$router.push({
        path: "/preferential",
        query: { selected: this.selected },
      });
    },

    listDocuments() {
      let documentArray = [];
      this.selected.forEach((document) => {
        document.documents.forEach((documento) => {
          documentArray.push(documento.description.toUpperCase());
        });
      });
      this.uniqueDocuments = [...new Set(documentArray)];
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
}

.grid-services {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  grid-gap: 35px;
  justify-content: center;
  padding-bottom: 5%;
}

.option_item {
  display: block;
  position: relative;
  width: 160px;
  height: 200px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 3px 3px 6px 1px #c4d7ed;
  margin-bottom: 1%;
  margin-top: 30%;
}

.option_item .option_inner {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 10px;
  text-align: center;
  padding: 58px 40px;
  display: block;
  border: 5px solid transparent;
  position: relative;
  margin-bottom: -30px;
}

.option_item .checkbox {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  opacity: 0;
}

.icon {
  margin-top: -20px;
}
.option_item .option_inner .name {
  display: flex;
  font-size: 16px;
  width: 100px;
  justify-content: center;
  padding-right: 30px;
  font-weight: bolder;
}

.option_item .checkbox:checked ~ .option_inner {
  border-color: #5c9df2;
  color: #5c9df2;
}

.option_item .option_inner .tickmark {
  position: absolute;
  top: -1px;
  left: 0px;
  border: 20px solid;
  border-color: #000 transparent transparent #000;
  display: none;
}

.option_item .option_inner .tickmark::before {
  content: "";
  position: absolute;
  top: -16px;
  left: -16px;
  width: 18px;
  height: 10px;
  border: 3px solid;
  border-color: transparent transparent white white;
  transform: rotate(-45deg);
}

.option_item .checkbox:checked ~ .option_inner .tickmark {
  display: block;
  border-color: #5c9df2 transparent transparent #5c9df2;
}
</style>
