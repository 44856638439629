<template>
  <v-app>
    <div class="img-background">
      <v-card-title
        class="text-center justify-center py-6 text-h5"
        style="margin-top: 10%; font-weight: bold"
      >
        Qual seu tipo de atendimento?
      </v-card-title>

      <v-container>
        <template>
          <PreferentialMain />
        </template>
        <br />
        <template>
          <PreferentialImages />
        </template>
      </v-container>
    </div>
  </v-app>
</template>

<script>
import preferentialimages from "../../components/preferentialComp/PreferentialImages.vue";
import preferentialmain from "../../components/preferentialComp/PreferentialMain.vue";

export default {
  components: {
    PreferentialMain: preferentialmain,
    PreferentialImages: preferentialimages,
  },
};
</script>

<style scoped>
.img-background {
  background-image: url("../../assets/fundo.png");
  background-size: cover;
  background-size: 50%;
  background-position: 50% -100%;
}
</style>
